import React, { useEffect, useState } from "react";
import Logo from "../logo/Logo";

const loginUrl = 'https://app.emailvalidify.com'
const signupUrl = 'https://app.emailvalidify.com/signup'


const Header = () => {

    const [navbarBG, setNavbarBG] = useState(false);


    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    useEffect(() => {
        const onResize = () => setIsMobile(window.innerWidth < 992);
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (isOpen) {
            document.getElementsByClassName('menu-toggle')[0]?.classList.add('active')
            document.getElementsByClassName('mobile-menu')[0]?.classList.add('open')
        } else {
            document.getElementsByClassName('menu-toggle')[0]?.classList.remove('active')
            document.getElementsByClassName('mobile-menu')[0]?.classList.remove('open')
        }
    }, [isOpen]);


    useEffect(() => {
        const heroSectionHeight = document.getElementById('about')?.offsetTop ?? 0;
        window.addEventListener("scroll", () => {
            console.log(heroSectionHeight, window.scrollY)
            setNavbarBG(window.scrollY > (heroSectionHeight - 100));
        });
    }, []);

    const toggleMenu = () => {

        console.log('Is mobile menu open', isOpen)

        setIsOpen(!isOpen)

    }

    const MenuItems = () => {
        return (
            <ul>
                <li><a className="nav-link scrollto active" href="/">Home</a></li>
                <li><a className="nav-link scrollto" href="/services">Services</a></li>
                <li><a className="nav-link scrollto" href="/pricing">Pricing</a></li>
                <li><a className="nav-link scrollto" href="/about">About</a></li>
                <li><a className="nav-link scrollto" href="/contact">Contact</a></li>
                <li><a className="nav-link scrollto" href={loginUrl}>Login</a></li>
                {!isMobile && (
                    <li><a className="getstarted scrollto" href={signupUrl}>Start for Free</a></li>
                )}
            </ul>
        )
    }

    return (
        <header id="header" className={`header fixed-top ${navbarBG ? 'with-bg' : ''}`}>
            <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                <a href="/">
                    <Logo />
                </a>

                <nav id="navbar" className="navbar">
                    {isMobile ? (
                        <>
                            <div onClick={toggleMenu} className="toggle-menu-container">
                                <div className="menu-toggle"><span></span></div>
                            </div>
                            <div className="mobile-menu">
                                <MenuItems />
                            </div>
                        </>

                    ) : (
                        <MenuItems />
                    )}
                </nav>
            </div>
        </header>
    )
}


export default Header;