import React from "react";

import HeroImg from '../../../assets/img/email-validation-service-hero-bg.png'
import ArrowRightIcon from 'remixicon-react/ArrowRightLineIcon'

import { EmailValidatorBar } from "../../components/email-validator/EmailValidatorBar";


const signupUrl = 'https://app.emailvalidify.com/signup'

const HeroSection = () => {
    return (
        <section id="hero" className="hero d-flex">
            <div className="container">
                <div className="row hero-columns">
                    <div className="col-lg-12 d-flex flex-column justify-content-center">
                        <h1 data-aos="fade-up" className="title"><span className="highlighted">Verify any email Address</span><br /> with the most complete email checker</h1>
                        <h2 data-aos="fade-up" data-aos-delay="400" className="subtitle">Ensure your promotional messages reach the right audience with a verified email list, minimizing bounce rates and optimizing email delivery.</h2>
                        <h2 data-aos="fade-up" data-aos-delay="400" className="subtitle highlighted">Create a free account and get 100 Free verifications</h2>
                        <div data-aos="fade-up" data-aos-delay="600">
                            <div className="text-center">
                                <a href={signupUrl} className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                    <span>Signup Now</span>
                                    <ArrowRightIcon size={20} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <EmailValidatorBar />

                </div>
            </div>

        </section>
    )
}

export default HeroSection;