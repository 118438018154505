import React from "react";

import HeroSection from "./components/HeroSection";
import HowItWorksSection from "./components/HowItWorks";
import KeyFeaturesSection from "./components/KeyFeaturesSection";
import BenefitsSection from "./components/BenefitsSection";
import WhyChooseUsSection from "./components/WhyChooseUsSection";
import FAQSection from "./components/FAQSection";

function MainPage() {
    return (
        <>
            <HeroSection />
            <HowItWorksSection />
            <KeyFeaturesSection />
            <BenefitsSection />
            <WhyChooseUsSection />
            <FAQSection />
        </>
    );
}

export default MainPage;